import(/* webpackMode: "eager", webpackExports: ["Button"] */ "__barrel_optimize__?names=Button!=!/usr/src/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/config/image-loader.ts");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/feature-tab/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/components/joinus-button/index.tsx");

"use client";
import React, { PropsWithChildren, useState } from "react";
import { Radio } from "antd";

import styles from "./index.module.scss";

const FeatureTab: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  /** 当前tab */
  const [active, setActive] = useState("1");

  const orientationTab = [
    { label: "服务商", value: "1" },
    { label: "用工企业", value: "2" },
    { label: "蓝领", value: "3" }
  ];

  return (
    <div className={`${styles["component-wrapper"]} w-full`}>
      <div className="box-border w-full min-w-[330px] px-4 pc:px-0">
        <Radio.Group
          size="large"
          optionType="button"
          buttonStyle="solid"
          options={orientationTab}
          block
          defaultValue="1"
          onChange={(e) => {
            if (e.target.checked) {
              setActive(e.target.value);
            }
          }}
        ></Radio.Group>
      </div>
      <div>{(children as any).filter((item: any) => item.key === active)}</div>
    </div>
  );
};

export default FeatureTab;

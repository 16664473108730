"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { Modal, Button, Input, Cascader, message, Form } from "antd";
import type { FormProps } from "antd";
import validator from "../../utils/validator";
import styles from "./index.module.scss";

type FieldType = {
  name?: string;
  enterpriseName?: string;
  city?: any;
  phone?: string;
};

interface PropsOwn {
  children?: React.ReactElement;
}

const FeatureTab = (props: PropsOwn) => {
  const { children } = props;
  const [form] = Form.useForm();
  const [modalVisble, setModalVisble] = useState<any>(false);
  const [treeList, setTreeList] = useState<any>([]);
  const [cityData, setCityData] = useState<any>([]);

  useEffect(() => {
    fetch("/pineAppleServer/common/dataDict/tree", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ params: [{ dataTypeCode: "common.district", level: 2 }] })
    }).then(async (response: any) => {
      const resData = await response.json();
      if (resData && resData?.result === 0) {
        setTreeList(resData?.data?.records[0] || []);
      }
    });
  }, []);

  const handleCancel = () => {
    setModalVisble(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const paramsData = {
      ...values,
      city: cityData[1]?.dataKey,
      cityName: cityData[1]?.dataValue,
      province: cityData[0]?.dataKey,
      provinceName: cityData[0]?.dataValue
    };
    fetch("/pineAppleServer/customerLead/enterpriseConsult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...paramsData })
    }).then(async (response: any) => {
      const resData = await response.json();
      if (resData && resData?.result === 0) {
        message.success(resData?.data || "提交成功，稍后会有专人与您联系！");
        setModalVisble(false);
        form?.resetFields?.();
        return;
      } else {
        message.error(resData?.data || "提交失败！");
      }
    });
  };
  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {};
  return (
    <>
      <div
        onClick={() => {
          setModalVisble(true);
        }}
      >
        {children || (
          <div
            className="flex h-[36px] w-[160px] items-center rounded-3xl bg-blue px-10 py-2"
            style={{ cursor: "pointer" }}
          >
            <span className="text-sm text-white">加入我们 &nbsp;{">"}</span>
          </div>
        )}
      </div>

      <Modal
        wrapClassName={`${styles["modal-content"]}`}
        width={300}
        height={350}
        title={
          <Image
            className="h-[60px] w-[300px] pc:hidden"
            src={`/image/partner_modal_title_logo.png`}
            width={300}
            height={60}
            alt="勤拿手-合作咨询"
          />
        }
        open={modalVisble}
        onCancel={handleCancel}
      >
        <div className="flex justify-center">
          <div className="hidden h-[350px] w-[210px] pc:block">
            <Image
              className="h-[350px] w-[210px]"
              src={`/image/partner_modal_title_logo_pc@2x.png`}
              width={210}
              height={350}
              alt="勤拿手-合作咨询"
            />
          </div>
          <div className="flex w-[350px] flex-wrap justify-center">
            <p className="text-2xs my-3 text-center pc:mb-0 pc:mt-6">填写联系方式，享受一对一入驻咨询服务</p>
            <Form
              form={form}
              style={{ width: "80%" }}
              name="basic"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item<FieldType> name="enterpriseName" rules={[{ required: true, message: "请输入企业名称" }]}>
                <Input size="large" maxLength={30} placeholder="请输入企业名称" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item<FieldType> name="name" rules={[{ required: true, message: "请输入姓名" }]}>
                <Input size="large" maxLength={12} placeholder="请输入姓名" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item<FieldType>
                name="phone"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!(validator.telephone(value) || validator.phone(value))) {
                        return Promise.reject(new Error("请输入正确联系方式"));
                      } else {
                        return Promise.resolve();
                      }
                    }
                  })
                ]}
              >
                <Input size={"large"} placeholder="请输入正确手机号或固话" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item<FieldType> name="city" rules={[{ required: true, message: "请选择城市" }]}>
                <Cascader
                  fieldNames={{
                    label: "dataValue",
                    value: "dataKey",
                    children: "items"
                  }}
                  size="large"
                  options={treeList}
                  placeholder={"请选择城市"}
                  onChange={(val, data) => {
                    setCityData(data);
                  }}
                />
              </Form.Item>

              <Form.Item style={{ marginTop: "15px" }} wrapperCol={{ span: 24 }}>
                <Button size="large" style={{ width: "100%" }} shape="round" type="primary" htmlType="submit">
                  提交咨询
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FeatureTab;

/**固定电话格式校验规则 */
const TELEPHONE = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
/** 手机格式校验 */
const PHONE = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
const Validator = {
  // 验证手机
  phone: (value: any) => PHONE.test(value),
  // 验证固话
  telephone: (value: any) => TELEPHONE.test(value)
};

export default Validator;
